//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as K033NR } from "../../Media/Icon/008.svg";
//Install
import { ReactComponent as G605IV } from "../../Media/Icon/011.svg";
//Install
import { ReactComponent as P3620T } from "../../Media/Icon/012.svg";

//Element
class Mobile extends Component {
    //Element
    Hamburger = () => {
        //Element
        let Icon  = document.getElementById( "Web_T0Q4F3" )
        //Element
        let Close = document.getElementById( "Web_Y5Y43R" )
        //Element
        let Nav   = document.getElementsByClassName( "dxi945" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Icon.classList.toggle( "d-none" )
        //Element
        Close.classList.toggle( "d-none" )
        //Element
        Nav.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Call = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.gtag_report_conversion()
        //Element
        window.location.href = "tel:+905316690519"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v505u2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 i27n06" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "button", { id: "Web_N8ZS49", name: "Web_N8ZS49", className: "float-start w-100 p-0 m-0 bg-transparent border-0 f6203i", type: "button" },
                                //Element
                                React.createElement( "span", { className: "float-start p-0 m-0 text-truncate text-start z8f93i" } ),
                                //Element
                                React.createElement( "div", { className: "d-flex justify-content-end align-items-center p-0 m-0 bg-transparent border-0 p6m413" },
                                    //Element
                                    React.createElement( "span", { className: "d-flex justify-content-center align-items-center p-0 m-0 w8607h" }, 
                                        //Element
                                        React.createElement( K033NR, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ab57s9" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-25 p-0 m-0 d926dh" },
                                //Element
                                React.createElement( "button", { id: "Web_T0Q4F3", name: "Web_T0Q4F3", className: "d-flex justify-content-start align-items-center p-0 m-0 bg-transparent border-0 eb350s", type: "button", onClick: this.Hamburger },
                                    //Element
                                    React.createElement( G605IV, {} )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_Y5Y43R", name: "Web_Y5Y43R", className: "d-flex justify-content-start align-items-center p-0 m-0 bg-transparent border-0 d-none m1wm43", type: "button", onClick: this.Hamburger },
                                    //Element
                                    React.createElement( K033NR, {} )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-50 p-0 m-0 t7i15v" },
                                //Element
                                React.createElement( "button", { id: "Web_TH502D", name: "Web_TH502D", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 ze24j1", type: "button" }, "Cukurovatur.com" )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-25 p-0 m-0 by8244" },
                                //Element
                                React.createElement( "button", { id: "Web_LLG412", name: "Web_LLG412", className: "d-flex justify-content-end align-items-center p-0 m-0 bg-transparent border-0 t50u09", type: "button", onClick: this.Call },
                                    //Element
                                    React.createElement( P3620T, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Mobile;