//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/001.svg";
//Install
import { ReactComponent as R8RKH6 } from "../../Media/Icon/003.svg";
//Install
import Reservation from "../Content/Reservation";
//Install
import Car from "../Content/Car";
//Install
import Question from "../Content/Question";
//Install
import Service from "../Content/Service";
//Install
import Campaign from "../Content/Campaign";
//Install
import Reference from "../Content/Reference";

//Element
class Rent extends Component {
    //Element
    componentDidMount(){
        //Element
        const Counters = document.querySelectorAll( ".fc4azc" )
        //Element
        const Speed    = "400"
        //Element
        Counters.forEach( Counter => {
            //Element
            const Animate = () => {
                //Element
                const Value = + Counter.getAttribute( "value" )
                //Element
                const Data  = + Counter.innerText
                //Element
                const Time  = Value / Speed
                //Element
                if( Data < Value ){
                    //Element
                    Counter.innerText = Math.ceil( Data + Time )
                    //Element
                    setTimeout( Animate, 50 )
                } else {
                    //Element
                    Counter.innerText = Value
                }
            }
            //Element
            Animate()
        })
        //Element
        document.title                                                   = "Adana Şoförlü Araç Kiralama Hizmetleri Cukurovatur.com'da"
        //Element
        document.getElementsByTagName( "meta" )[ "description" ].content = "Şoförlü Araç Kiralama, Şoförlü Vito Kiralama, Şehirlerarası Şoförlü Araç Kiralama, Şoförlü Araç Kiralama Fiyatları İçin Hemen Teklif Alın Kazançlı Çıkın!"
        //Element
        document.getElementsByTagName( "meta" )[ "keyword" ].content     = "Şoförlü Araç Kiralama, Şoförlü Vito Kiralama, Şehirlerarası Şoförlü Araç Kiralama, Şoförlü Araç Kiralama Fiyatları "
    }
    //Element
    One = () => {
        //Element
        let Panel = document.getElementsByClassName( "c1yn4s" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Two = () => {
        //Element
        let Panel = document.getElementsByClassName( "utux26" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Three = () => {
        //Element
        let Panel = document.getElementsByClassName( "cz5j6q" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Four = () => {
        //Element
        let Panel = document.getElementsByClassName( "kc4im5" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Five = () => {
        //Element
        let Panel = document.getElementsByClassName( "tt60ue" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Six = () => {
        //Element
        let Panel = document.getElementsByClassName( "ym48gy" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Seven = () => {
        //Element
        let Panel = document.getElementsByClassName( "t42t5s" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Eight = () => {
        //Element
        let Panel = document.getElementsByClassName( "d2hpa6" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Nine = () => {
        //Element
        let Panel = document.getElementsByClassName( "t8u6ty" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Ten = () => {
        //Element
        let Panel = document.getElementsByClassName( "kdfg47" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Offer = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "cam386" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        SwiperCore.use([ Navigation, Pagination, Autoplay ])
        //Element
        const Questions = {
            //Element
            spaceBetween  : 12,
            //Element
            slidesPerView : 4,
            //Element
            navigation    : {
                //Element
                prevEl    : "#Web_MH0X5T",
                //Element
                nextEl    : "#Web_XZL5GI"
            },
            //Element
            autoplay      : {
                //Element
                delay     : 3000
            },
            //Element
            breakpoints   : { 
                //Element
                240       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 0
                },
                //Element
                320       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                480       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                768       : {
                    //Element
                    slidesPerView : 3,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                1024       : {
                    //Element
                    slidesPerView : 4,
                    //Element
                    spaceBetween  : 12
                }
            },
            //Element
            loop          : true
        }
        //Element
        const Services = {
            //Element
            spaceBetween  : 12,
            //Element
            slidesPerView : 5,
            //Element
            navigation    : {
                //Element
                prevEl    : "#Web_FHP7Z7",
                //Element
                nextEl    : "#Web_DHE3B6"
            },
            //Element
            autoplay      : {
                //Element
                delay: 2000
            },
            //Element
            breakpoints   : { 
                //Element
                240       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 0
                },
                //Element
                320       : {
                    //Element
                    slidesPerView : 1,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                480       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                768       : {
                    //Element
                    slidesPerView : 2,
                    //Element
                    spaceBetween  : 12
                },
                //Element
                1024       : {
                    //Element
                    slidesPerView : 5,
                    //Element
                    spaceBetween  : 12
                }
            },
            //Element
            loop          : true
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0" },
                //Element
                React.createElement( "div", { className: "float-start py6h3z" },
                    //Element
                    React.createElement( "figure", { className: "float-start w-100 p-0 m-0 uv8hcm" } )
                ),
                //Element
                React.createElement( "div", { className: "d-flex flex-column justify-content-center align-items-center irru58" },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( Reservation, { ID: "0", Title: "Şoförlü Araç Kiralama Hizmetlerinde Bugünlere Özel %10 İndirim Fırsatı Cukurovatur.com'da", Description: "Adana Şoförlü Araç Kiralama Hizmetlerinde Bugüne Özel %10 İndirim Fırsatı", Telephone: "+90 531 669 05 19" } )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 u0x92c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 mds87o" },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 o89bjv" }, "Kurumsal" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 fc4azc", value: "34" } )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 mds87o" },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 o89bjv" }, "Bireysel" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 fc4azc", value: "181" } )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 mds87o" },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 o89bjv" }, "Yolcu" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 fc4azc", value: "963" } )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 mds87o" },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0 o89bjv" }, "Konum" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 fc4azc", value: "74" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mj6wg0" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Car, { ID: "0", Title: "Citroen Elysee 299.99 TL'den Başlayan Fiyatlarla", Brand: "Citroen", Model: "Elysee 1.6 BlueHDI", Discount: "399.99", Photo: "006.png" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Car, { ID: "0", Title: "Fiat Egea 499.99 TL'den Başlayan Fiyatlarla", Brand: "Fiat", Model: "Egea 1.6 Multijet", Discount: "399.99", Photo: "007.png" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Car, { ID: "0", Title: "Mercedes-Benz C Serisi 649.99 TL'den Başlayan Fiyatlarla", Brand: "Mercedes-Benz", Model: "C 200 d BlueTEC", Discount: "499.99", Photo: "008.png" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Car, { ID: "0", Title: "Mercedes - Benz Vito 1.499.99 TL'den Başlayan Fiyatlarla", Brand: "Mercedes-Benz", Model: "Vito 116 CDI", Discount: "699.99", Photo: "009.png" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { id: "Faq", className: "float-start w-100 p-0 m-0 evhg4k" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Questions },
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.One },
                                        //Element
                                        React.createElement( Question, { ID: "1", Title: "Transfer hizmetleri için nasıl online rezervasyon yapabilirim?" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.Two },
                                        //Element
                                        React.createElement( Question, { ID: "2", Title: "Online rezervasyon yaptıktan sonra nasıl güncelleme yapabilirim?" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.Three },
                                        //Element
                                        React.createElement( Question, { ID: "3", Title: "Belirtilen ücretler haricinde başka ödeme yapmam gerekir mi?" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.Four },
                                        //Element
                                        React.createElement( Question, { ID: "4", Title: "Hangi il ve ilçelere havalimanı transfer hizmetleri sunulmaktadır?" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.Five },
                                        //Element
                                        React.createElement( Question, { ID: "5", Title: "Rezervasyon yapabilmek için gereken şart ve koşullar nelerdir?" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.Six },
                                        //Element
                                        React.createElement( Question, { ID: "6", Title: "Rezervasyon yapılan transfer hizmetler için nasıl ödeme yapabilirim?" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.Seven },
                                        //Element
                                        React.createElement( Question, { ID: "7", Title: "Havalimanı terminalinde araç şoförünü nerede ve nasıl bulabilirim?" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.Eight },
                                        //Element
                                        React.createElement( Question, { ID: "8", Title: "Satın alınan transfer hizmeti için nasıl fatura talep edebilirim?" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.Nine },
                                        //Element
                                        React.createElement( Question, { ID: "9", Title: "Rezervasyon yapılan transfer hizmetini nasıl iptal edebilirim?" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, { className: "float-start", onClick: this.Ten },
                                        //Element
                                        React.createElement( Question, { ID: "10", Title: "Uçağımda rötar olursa ekstra bir ödeme yapmam gerekecek mi?" } )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 position-absolute hp02uq" },
                                    //Element
                                    React.createElement( "button", { id: "Web_MH0X5T", name: "Web_MH0X5T", className: "float-start p-0 mib54x", type: "button" },
                                        //Element
                                        React.createElement( R8RKH6, {} )
                                    ),
                                    //Element
                                    React.createElement( "button", { id: "Web_XZL5GI", name: "Web_XZL5GI", className: "float-end p-0 kw19al", type: "button" },
                                        //Element
                                        React.createElement( OP7WOW, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mc92h9" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-relative" },
                    //Element
                    React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Services },
                        //Element
                        React.createElement( SwiperSlide, { className: "float-start", onClick: this.Offer },
                            //Element
                            React.createElement( Service, { ID: "0", Title: "Minibüs Kiralama", Photo: "003.webp", Target: "/minibus-kiralama" } )
                        ),
                        //Element
                        React.createElement( SwiperSlide, { className: "float-start" },
                            //Element
                            React.createElement( Service, { ID: "0", Title: "Midibüs Kiralama", Photo: "", Target: "/midibus-kiralama" } )
                        ),
                        //Element
                        React.createElement( SwiperSlide, { className: "float-start" },
                            //Element
                            React.createElement( Service, { ID: "0", Title: "Otobüs Kiralama", Photo: "006.webp", Target: "/otobus-kiralama" } )
                        ),
                        //Element
                        React.createElement( SwiperSlide, { className: "float-start" },
                            //Element
                            React.createElement( Service, { ID: "0", Title: "Otomobil Kiralama", Photo: "004.webp", Target: "/soforlu-arac-kiralama" } )
                        ),
                        //Element
                        React.createElement( SwiperSlide, { className: "float-start" },
                            //Element
                            React.createElement( Service, { ID: "0", Title: "Karavan Kiralama", Photo: "005.webp", Target: "/" } )
                        ),
                        //Element
                        React.createElement( SwiperSlide, { className: "float-start" },
                            //Element
                            React.createElement( Service, { ID: "0", Title: "Havalimanı Transfer", Photo: "007.webp", Target: "/havalimani-transfer" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 ps-4 pe-4 m-0 position-absolute start-0 top-0 wg4e2a" },
                        //Element
                        React.createElement( "button", { id: "Web_FHP7Z7", name: "Web_FHP7Z7", className: "float-start position-relative p-0 border-0 rounded-circle r0mgbu", type: "button" },
                            //Element
                            React.createElement( R8RKH6, {} )
                        ),
                        //Element
                        React.createElement( "button", { id: "Web_DHE3B6", name: "Web_DHE3B6", className: "float-end position-relative p-0 border-0 rounded-circle r0mgbu", type: "button" },
                            //Element
                            React.createElement( OP7WOW, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { id: "Campaign", className: "float-start w-100 p-0 m-0 p93hn3" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Campaign, { ID: "0", Title: "Tüm Havalimanı Transfer Hizmetlerinde Geçerli Bugüne Özel %10 İndirim Fırsatı Cukurovatur.com'da", Summary: "Tüm Havalimanı Transfer Hizmetlerinde Geçerli %10 İndirim Fırsatı Cukurovatur.com'da", Photo: "002.jpg" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Campaign, { ID: "0", Title: "Şoförlü Minibüs Kiralama Hizmetlerinde Geçerli Olan Bugüne Özel %10 İndirim Fırsatı Cukurovatur'da", Summary: "Şoförlü Minibüs Kiralama Hizmetlerinde Geçerli %10 İndirim Fırsatı Cukurovatur.com'da", Photo: "001.webp" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 i9zlkh" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Reference, { ID: "0", Title: "TAV Airport", Photo: "01.webp" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Reference, { ID: "0", Title: "Divan Otel", Photo: "02.webp" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Reference, { ID: "0", Title: "Hilton Hotel", Photo: "03.webp" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Reference, { ID: "0", Title: "Bossa", Photo: "06.webp" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Reference, { ID: "0", Title: "Casper", Photo: "07.webp" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Reference, { ID: "0", Title: "OPET", Photo: "08.webp" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g29s43" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-center p-5 m-0 mt-5 zq861t" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 lh-1" }, "Şoförlü Araç Kiralama" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3" }, "Şoförlü Araç Kiralama, Şehirlerarası Şoförlü Araç Kiralama Fiyatları, Şoförlü Araç Kiralama Fiyatları, Şoförlü Vip Araç Kiralama, Şoförlü Vip Araç Kiralama Fiyatları, Şoförlü Vip Araç Kiralama Fiyatları, Soforlu Araç Kiralama, Şehirler Arası Şoförlü Araç Kiralama, Günlük Şoförlü Araç Kiralama, Özel Şoförlü Araç Kiralama, Vip Soforlu Arac Kiralama, Vip Şoförlü Araç Kiralama, Kiralık Şoförlü Araç, Şoförlü Araç Kiralama Ücretleri" ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 mt-3 lh-1" }, "Şoförlü Otomobil Kiralama" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3" }, "Şoförlü Araba Kiralama, Şoförlü Araç Kiralama Fiyatları, Şöförlü Kiralık Araç, Şehirlerarası Şoförlü Araç Kiralama Fiyatları, Araç Kiralama Şöförlü, Vip Araç Kiralama Şöförlü, Şöförlü Araç Kiralama Şehirlerarası, Şoförlü Araç, Günlük Şöförlü Araç Kiralama Fiyatları, Soforlu Araba Kiralama" ),
                                //Element
                                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mt-3 lh-1" }, "Şoförlü Vito Kiralama" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3" }, "Günlük Şoförlü Araç Kiralama, Kiralık Şöförlü Araba, Şoförlü Araç Kiralama Şehirlerarası, Şoförlü Kiralık Araba Fiyatları, Şehirlerarası Şöförlü Araç Kiralama, şehirlerarası Şöförlü Araç Kiralama Fiyatları, Vip Araç Kiralama Şoförlü, Sehirler Arasi Soforlu Arac Kiralama, Vip Şoförlü Araç Kiralama, Aylık Şöförlü Araç Kiralama" ),
                                //Element
                                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mt-3 lh-1" }, "Şoförlü Minibüs Kiralama" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3" }, "Şöförlü Araba Kiralama Fiyatları, Şöförlü Araç Kiralama Ücretleri, Şoförlü Araba Kiralama, Özel Şoförlü Araç Hizmeti, Kiralık Araba Şöförlü, Vip Soforlu Arac Kiralama, Özel Şoförlü Araç Kiralama Fiyatları, Şoförlü Kiralık Araba, Aylık Şöförlü Araç Kiralama, Şöförlü Araba Kiralama Fiyatları, Özel Şöförlü Araç Kiralama Fiyatları" ),
                                //Element
                                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mt-3 lh-1" }, "Şoförlü Otobüs Kiralama" ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3" }, "Şoförlü Vito Kiralama, Şoförlü Minibüs Kiralama Fiyatları, Şoförlü Vito Kiralama Fiyat, Şöförlü Mercedes Vito Kiralama, Vito Kiralama Şöförlü, Şoförlü Lüks Araç Kiralama, Şöförlü Mercedes Kiralama, Şöförlü Kiralık Minibüs, Kiralık Minibüs Şöförlü, Şöförlü Sprinter Kiralama, Kiralık Şöförlü Minibüs Fiyatları Cukurovatur.com'da" )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Rent;