//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Customer from "./Footer/Customer";
//Install
import Copyright from "./Footer/Copyright";
//Install
import ScrollTop from "./Footer/ScrollTop";
//Install
import Social from "./Footer/Social";
//Install
import Bottom from "./Mobile/Bottom";

//Element
class Footer extends Component {
    //Element
    About = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "h418q5" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Car = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "vqj03t" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Career = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "p1zei3" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Reference = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "o52cca" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Campaign = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "p93hn3" )[0]
        //Element
        Panel.scrollIntoView()
    }
    //Element
    Question = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "evhg4k" )[0]
        //Element
        Panel.scrollIntoView()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 l2lmgb" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s2336m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 lh7k10" } ),
                                //Element
                                React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-4 ap931w" },
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", href: "/", onClick: this.About }, "Hakkımızda" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Modeller", href: "/", onClick: this.Car }, "Modeller" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Referanslar", href: "/", onClick: this.Reference }, "Referanslar" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Kampanyalar", href: "/", onClick: this.Campaign }, "Kampanyalar" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Sıkça Sorulan Sorular", href: "/", onClick: this.Question }, "Sıkça Sorulan Sorular" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 hl3z1o" } ),
                                //Element
                                React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-4 ap931w" },
                                    //Element
                                    React.createElement( "li", { className: "float-start w-50" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Havalimanı Transfer", href: "/havalimani-transfer" }, "Havalimanı Transfer" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-50" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Şoförlü Araç Kiralama", href: "/soforlu-arac-kiralama" }, "Şoförlü Araç Kiralama" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-50" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-150 lh-1", title: "Karavan Kiralama", href: "/" }, "Karavan Kiralama" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-50" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Minibüs Kiralama", href: "/minibus-kiralama" }, "Minibüs Kiralama" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-50" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Midibüs Kiralama", href: "/" }, "Midibüs Kiralama" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-50" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-150 lh-1", title: "Araç Kiralama", href: "/" }, "Araç Kiralama" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-50" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Otobüs Kiralama", href: "/otobus-kiralama" }, "Otobüs Kiralama" )
                                    ),
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 ji0eiq" } ),
                                //Element
                                React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-4 ap931w" },
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Kişisel Verilerin Korunması", href: "https://media.cukurovatur.com/kisisel-verilerin-korunmasi.pdf" }, "Kişisel Verilerin Korunması" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Çerez Politikaları", href: "https://media.cukurovatur.com/cerez-politikalari.pdf" }, "Çerez Politikaları" )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100" },
                                        //Element
                                        React.createElement( "a", { className: "float-start w-100 lh-1", title: "Başvuru Formu", href: "https://media.cukurovatur.com/ilgili-kisi-basvuru-formu.pdf" }, "Başvuru Formu" )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5" },
                                //Element
                                React.createElement( Customer, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 f7f61y" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 pz7rb2" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:9 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2" },
                                            //Element
                                            React.createElement( Copyright, {} )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:3 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2" },
                                            //Element
                                            React.createElement( Social, { ID: "0", Facebook: "/", WhatsApp: "https://wa.me/+905316690519", Instagram: "/", Youtube: "/", Linkedin: "/" } ),
                                            //Element
                                            React.createElement( ScrollTop, {} )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Bottom, {} )
        )
        //Element
        return (
            <footer className="float-start w-100">
                { Content }
            </footer>
        );
    }
}
//Element
export default Footer;