//Install
import React, { Component } from "react";
//Install
import { ReactComponent as TGY82A } from "../../Media/Icon/004.svg";
//Install
import { ReactComponent as P9HQ1I } from "../../Media/Icon/005.svg";
//Install
import { ReactComponent as MR8Q35 } from "../../Media/Icon/006.svg";

//Element
class Customer extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 jm53mu" },
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative p-0 pb-4 m-0 g2yvoq" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 q4pawv" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-1 un4knp" }, "Haritalar" ),
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-2 ok8n1t", title: "Konumu Haritada Bulun", href: "/" }, "Konumu Haritada Bulun" )
                ),
                //Element
                React.createElement( "div", { className: "float-end sfcs1u" },
                    //Element
                    React.createElement( TGY82A, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative g2yvoq" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 q4pawv" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-1 un4knp" }, "Bilgi Edinme" ),
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-2 ok8n1t", title: "Hemen Bildirim Gönderin", href: "mailto:info@cukurovatur.com" }, "Hemen Bildirim Gönderin" )
                ),
                //Element
                React.createElement( "div", { className: "float-end sfcs1u" },
                    //Element
                    React.createElement( MR8Q35, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative g2yvoq" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 q4pawv" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-1 un4knp" }, "Çağrı Merkezi" ),
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-2 ok8n1t", title: "Hemen Görüşme Başlatın", href: "tel:+905316690519" }, "Hemen Görüşme Başlatın" )
                ),
                //Element
                React.createElement( "div", { className: "float-end sfcs1u" },
                    //Element
                    React.createElement( P9HQ1I, {} )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Customer;