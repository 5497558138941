//Install
import React, { Component } from "react";
//Install
import { ReactComponent as VYP7PF } from "../../Media/Icon/001.svg";
//Install
import propTypes from "prop-types";

//Element
class Service extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo } = this.props
        //Element
        let Source
        //Element
        Source = "https://www.cukurovatur.com/upload/business/"
        //Element
        let Image
        //Element
        Image  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "button", { className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 aeyq8m", type: "button" },
                //Element
                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 upu55b", style: { backgroundImage: "url(" + Image + ")"} } ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 iap9ii" },
                    //Element
                    React.createElement( "h6", { className: "float-start p-0 m-0 mt-3 lh-1 s7k8tq" }, Title ),
                    //Element
                    React.createElement( "span", { className: "float-end p-0 m-0 mt-3 zfod06" },
                        //Element
                        React.createElement( VYP7PF, {} )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Service.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Service;