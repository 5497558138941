//Install
import React from "react";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "swiper/css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import City from "./Component/Page/City";
//Install
import Airport from "./Component/Page/Airport";
//Install
import Rent from "./Component/Page/Rent";
//Install
import Minibus from "./Component/Page/Minibus";
//Install
import Bus from "./Component/Page/Bus";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/adana-transfer" element={ <City /> }></Route>
          <Route path="/havalimani-transfer" element={ <Airport /> }></Route>
          <Route path="/soforlu-arac-kiralama" element={ <Rent /> }></Route>
          <Route path="/minibus-kiralama" element={ <Minibus /> }></Route>
          <Route path="/otobus-kiralama" element={ <Bus /> }></Route>
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;
