//Install
import React, { Component } from "react";
//Install
import { ReactComponent as GG0IB7 } from "../../Media/Icon/007.svg";

//Element
class ScrollTop extends Component {
    //Element
    componentDidMount(){
        //Element
        window.addEventListener( "scroll", this.Scroll )
    }
    //Element
    Scroll = () => {
        //Element
        let Button = document.getElementById( "Web_K0B3Y4" )
        //Element
        let Scroll = window.scrollY
        //Element
        if( Scroll > 500 ){
            //Element
            Button.style.opacity = "1"
        } else {
            //Element
            Button.style.opacity = "0"
        }
    }
    //Element
    scrollToTop = () => {
        //Element
        let Element = document.documentElement
        //Element
        Element.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-end" },
            //Element
            React.createElement( "button", { id: "Web_K0B3Y4", name: "Web_K0B3Y4", className: "float-end p-0 m-0 mt-2 rounded-circle bo9cre", type: "button", onClick: this.scrollToTop },
                //Element
                React.createElement( GG0IB7, {} )
            )
        )
        //Element
        return (
            <div className="float-start w-auto">
                { Content }
            </div>
        );
    }
}
//Element
export default ScrollTop;