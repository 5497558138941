//Install
import React, { Component } from "react";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/001.svg";
//Install
import propTypes from "prop-types";

class Car extends Component {
    //Element
    Modal = (e) => {
        //Element
        let Panel = document.getElementsByClassName( "vqj03t" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const { Title, Brand, Model, Discount, Photo } = this.props
        //Element
        let Source
        //Element
        Source = "https://www.cukurovatur.com/upload/car/"
        //Element
        let Image
        //Element
        Image = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "button", { className: "float-start w-100 p-0 m-0 mt-4 rl50sq", type: "button", onClick: this.Modal },
                //Element
                React.createElement( "header", { className: "float-start w-100 p-5 pb-4 m-0" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 lh-1 text-start eid7u3" }, Brand ),
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 text-start eid7u3" }, Model )
                ),
                //Element
                React.createElement( "picture", { className: "d-flex w-100 justify-content-center align-items-center p-0 ps-4 pe-4 pt-3 pb-3 uj5nfu" },
                    //Element
                    React.createElement( "img", { alt: Title, title: Title, src: Image, width: "317", height: "178" } )
                ),
                //Element
                React.createElement( "footer", { className: "float-start w-100 p-5 pt-4 pb-5" },
                    //Element
                    React.createElement( "span", { className: "float-start p-0 m-0 lh-1 x4pryx" }, Discount ),
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 text-start g2vezx" }, 
                        //Element
                        React.createElement( "strong", {}, "Hemen Bakın" ),
                        //Element
                        React.createElement( OP7WOW, {} )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
Car.propTypes = {
    ID       : propTypes.string.isRequired,
    Title    : propTypes.string.isRequired,
    Brand    : propTypes.string.isRequired,
    Model    : propTypes.string.isRequired,
    Discount : propTypes.string.isRequired,
    Photo    : propTypes.string.isRequired
}
//Element
export default Car;