//Install
import React, { Component } from "react";

//Element
class Brand extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { id: "Web_DO4Q27", name: "Web_DO4Q27", className: "float-start p-0 m-0 bg-transparent border-0 ql07g9", type: "button", onClick: this.Home }, 
            //Element
            "Cukurovatur.com"
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Brand;