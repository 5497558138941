//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Copyright extends Component {
    //Element
    render() {
        //Element
        const { Text } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 j7n7dd" },
            //Element
            React.createElement( "span", { className: "float-start p-0 m-0 text-start fxu40m" }, Text )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Copyright.propTypes = {
    ID   : propTypes.string.isRequired,
    Text : propTypes.string.isRequired
}
//Element
export default Copyright;