//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Top from "./Header/Top";
//Install
import Brand from "./Header/Brand";
//Install
import Menu from "./Header/Menu";
//Install
import SET from "./Header/SET";
//Install
import Assets from "./Header/Assets";
//Install
import Questions from "./Header/Questions";
//Install
import GET from "./Function/GET";
//Install
import Mobile from "./Mobile/Mobile";
//Install
import Navigation from "./Mobile/Navigation";

//Element
class Header extends Component {
    //Element
    componentDidMount(){
        //Element
        let Desktop       = document.getElementsByClassName( "wu5vo5" )[0]
        //Element
        let Desktop_Line = document.getElementsByClassName( "d4uzu4" )[0]
        //Element
        let Mobile        = document.getElementsByClassName( "z8f93i" )[0]
        //Element
        let Mobile_Line = document.getElementsByClassName( "i27n06" )[0]
        //Element
        Desktop.innerHTML = "Bu sitenin daha iyi, daha hızlı ve güvenli çalışması için çerez kullanılmaktadır. Site üzerinde kullanılan çerezler hakkında detaylı bilgi almak için Çerez Politikası'nı incelemenizi rica ederiz."
        //Element
        Mobile.innerHTML  = "Bu sitenin daha iyi, daha hızlı ve güvenli çalışması için çerez kullanılmaktadır. Site üzerinde kullanılan çerezler hakkında detaylı bilgi almak için Çerez Politikası'nı incelemenizi rica ederiz."
        //Element
        let Cookie = GET( "Cookie" )
        //Element
        if( Cookie == "OK" ){
            //Element
            Desktop_Line.classList.add( "d-none" )
            //Element
            Mobile_Line.classList.add( "d-none" )
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d4uzu4" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Top, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 m6t2z4" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex flex-column justify-content-center align-items-start b46m84" },
                                //Element
                                React.createElement( Brand, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:7 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Menu, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( SET, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Assets, {} ),
            //Element
            React.createElement( Questions, {} ),
            //Element
            React.createElement( Mobile, {} ),
            //Element
            React.createElement( Navigation, {} )
        )
        //Element
        return (
            <header className="float-start w-100">
                { Content }
            </header>
        );
    }
}
//Element
export default Header;