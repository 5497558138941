//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";
//Install
import { ReactComponent as HU05NC } from "../../Media/Icon/001.svg";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/008.svg";
//Install
import { ReactComponent as XU80MP } from "../../Media/Icon/009.svg";
//Install
import { ReactComponent as W41W8D } from "../../Media/Icon/010.svg";
//Install
import Copyright from "../Shortcode/Copyright";
//Install
import propTypes from "prop-types";

//Element
class Contact extends Component {
    //Element
    Close = () => {
        //Element
        let Panel = document.getElementsByClassName( "eo3q6d" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Show = (e) => {
        //Element
        let Dropdown = document.getElementById( "Web_J338MZ" )
        //Element
        let Down     = document.getElementById( "Web_P078HB" )
        //Element
        let Up       = document.getElementById( "Web_Q5C52J" )
        //Element
        Dropdown.classList.toggle( "d-none" )
        //Element
        Down.classList.toggle( "d-none" )
        //Element
        Up.classList.toggle( "d-none" )
    }
    //Element
    Select = (e) => {
        //Element
        let Headline       = document.getElementById( "Web_V63B0U" )
        //Element
        let Text           = e.nativeEvent.srcElement.textContent
        //Element
        Headline.innerHTML = Text
    }
    //Element
    Send = async(e) => {
        //Element
        e.preventDefault()
        //Element
        emailjs.sendForm( process.env.REACT_APP_Email_GG990T, process.env.REACT_APP_Email_BJ476J, "#Web_GA938S", process.env.REACT_APP_EMail_K8NY19 )
        //Element
        .then( (result) => {
            //Element
            if( result.text === "OK" ){
                //Element
                let Panel   = document.getElementsByClassName( "p1zei3" )[0]
                //Element
                let Success = document.getElementsByClassName( "z2grys" )[0]
                //Element
                Panel.classList.toggle( "d-none" )
                //Element
                Success.classList.toggle( "d-none" )
            }
        }, (error) => {
            //Element
            console.log( error.text )
        })
    }
    //Element
    render() {
        //Element
        const { Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start p-4 pb-3 m-0 bg-white a4rjup" },
            //Element
            React.createElement( "div", { className: "d-flex justify-content-between align-items-center cwo9uh" },
                //Element
                React.createElement( "div", { className: "float-start rounded-circle i562jl" },
                    //Element
                    React.createElement( "span", { className: "d-inline-block w-100 text-center text-white" }, "C" )
                ),
                //Element
                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 text-start nsu2sb" }, Title ),
                //Element
                React.createElement( "button", { className: "float-end p-0 m-0 bg-transparent border-0 j9h4yq", type: "button", onClick: this.Close },
                    //Element
                    React.createElement( OP7WOW, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-4 ps-0 pe-0 pt-3 m-0 bg-white overflow-hidden gu8r99" },
                //Element
                React.createElement( "form", { id: "Web_GA938S", name: "Web_GA938S", className: "float-start w-100 p-0 m-0 r0s1nk", onSubmit: this.Send },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 y7bh7e" }, "Yolcularımıza konforlu ve kaliteli bir hizmet sunmak amacıyla kendimizi sürekli geliştiriyor ve uçuş konusunda merak ettikleri tüm bilgilere kolayca ulaşabilmelerini önemsiyoruz." ),
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_C2AN67", name: "Web_C2AN67", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-3 border-0 ukg2q3", placeholder: "Ad ve Soyad", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative p-0 m-0 mt-3", onClick: this.Show },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 pe-4 ps-4 m-0 k7ko21" },
                                    //Element
                                    React.createElement( "span", { id: "Web_V63B0U", className: "float-start p-0 m-0 tlux1x" }, "Konu" ),
                                    //Element
                                    React.createElement( "span", { id: "Web_P078HB", className: "d-flex justify-content-end align-items-center p-0 m-0 mqy34l dmcq63" },
                                        //Element
                                        React.createElement( XU80MP, {} )
                                    ),
                                    //Element
                                    React.createElement( "span", { id: "Web_Q5C52J", className: "d-flex justify-content-end align-items-center p-0 m-0 d-none mqy34l j0d4qh" },
                                        //Element
                                        React.createElement( W41W8D, {} )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Web_J338MZ", className: "float-start w-100 p-0 ps-4 pe-4 m-0 position-absolute bg-white d-none i3qpcs" },
                                    //Element
                                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 position-relative", onClick: this.Select }, "Öneri" )
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 position-relative", onClick: this.Select }, "Şikayet" )
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 position-relative", onClick: this.Select }, "Bilgi Talebi" )
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 position-relative", onClick: this.Select }, "Teşekkür Ederim" )
                                        )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_KM88A6", name: "Web_KM88A6", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", placeholder: "Telefon numaranız", type: "tel", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_Z831AL", name: "Web_Z831AL", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", placeholder: "E-mail adresiniz", type: "email", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "textarea", { id: "Web_P46133", name: "Web_P46133", className: "float-start w-100 p-3 ps-4 pe-4 m-0 mt-4 r7elfb", row: "4", cols: "50", placeholder: "Mesaj", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "button", { id: "Web_W0PHNG", name: "Web_W0PHNG", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 text-white text-center h0f232", type: "submit" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 a580qx" }, "Hemen Gönder" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end w-auto p-0 m-0 xmq5yh" },
                                        //Element
                                        React.createElement( HU05NC, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Copyright, { ID: "0", Text: "Telif Hakkı © 2018 - 2022 Cukurovatur.com Tüm Hakları Saklıdır" } )
        )
        //Element
        return (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center position-fixed start-0 end-0 top-0 bottom-0 d-none j6oapg eo3q6d">
                { Content }
            </div>
        );
    }
}
//Element
Contact.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Contact;