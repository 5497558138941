//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as CM784M } from "../../Media/Icon/014.svg";
//Install
import { ReactComponent as X5X71C } from "../../Media/Icon/015.svg";
//Install
import { ReactComponent as L759OG } from "../../Media/Icon/016.svg";
//Install
import { ReactComponent as UA9243 } from "../../Media/Icon/012.svg";

//Element
class Bottom extends Component {
    //Element
    Home = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "/"
    }
    //Element
    Mail = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "mailto:info@cukurovatur.com"
    }
    //Element
    Chat = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "https://wa.me/+905316690519"
    }
    //Element
    Call = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "tel:+905316690519"
        //Element
        window.gtag_report_conversion()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 k4184s" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-25 p-0 m-0 h01745" },
                            //Element
                            React.createElement( "button", { id: "Web_U83PL5", name: "Web_U83PL5", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 iv656m", type: "button", onClick: this.Home },
                                //Element
                                React.createElement( CM784M, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-25 p-0 m-0 h01745" },
                            //Element
                            React.createElement( "button", { id: "Web_M955M8", name: "Web_M955M8", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 iv656m", type: "button", onClick: this.Mail },
                                //Element
                                React.createElement( X5X71C, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-25 p-0 m-0 h01745" },
                            //Element
                            React.createElement( "button", { id: "Web_XN7874", name: "Web_XN7874", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 iv656m", type: "button", onClick: this.Chat },
                                //Element
                                React.createElement( L759OG, {} )
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-25 p-0 m-0 t7gb19" },
                            //Element
                            React.createElement( "button", { id: "Web_NQW508", name: "Web_NQW508", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 s5m17d", type: "button", onClick: this.Call },
                                //Element
                                React.createElement( UA9243, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 p-0 m-0 position-fixed start-0 bottom-0 rh3569">
                { Content }
            </div>
        );
    }
}
//Element
export default Bottom;