//Install
import React, { Component } from "react";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/001.svg";
//Install
import propTypes from "prop-types";

//Element
class Question extends Component {
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "button", { className: "float-start w-100 p-0 m-0 mt-4 bg-transparent o8rd7h", type: "button" },
                //Element
                React.createElement( "header", { className: "float-start w-100 p-5 m-0" },
                    //Element
                    React.createElement( "span", { className: "float-start p-0 m-0 text-center rounded-circle jia7d3" }, ID ),
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-3 text-start mx6tgz" }, Title ),
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-start lh-1 g2vezx" }, 
                        //Element
                        React.createElement( "strong", {}, "Hemen Bakın" ),
                        //Element
                        React.createElement( OP7WOW, {} )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Question.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired
}
//Element
export default Question;