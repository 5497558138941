//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Campaign extends Component {
    //Element
    Modal = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "j2766d" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const { Title, Summary, Photo } = this.props
        //Element
        let Source
        //Element
        Source = "https://www.cukurovatur.com/upload/campaign/"
        //Element
        let Image
        //Element
        Image  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "button", { className: "float-start w-100 p-0 m-0 mt-5 bg-transparent border-0 sn6nkq", type: "button", onClick: this.Modal },
                //Element
                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 n4jv1n", style: { backgroundImage: "url(" + Image + ")" } } ),
                //Element
                React.createElement( "div", { className: "float-start w-100 iap9ii" },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 bg-white l6uxsi" },
                        //Element
                        React.createElement( "h4", { className: "float-start w-100 p-0 m-0 text-start vm3i75" }, Title ),
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 text-start text-truncate fw6ki0" }, Summary )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Campaign.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Photo   : propTypes.string.isRequired
}
//Element
export default Campaign;