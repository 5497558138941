//Install
import React, { Component } from "react";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/008.svg";
//Install
import Copyright from "../Shortcode/Copyright";
//Install
import propTypes from "prop-types";

//Element
class Ten extends Component {
    //Element
    Close = () => {
        //Element
        let Panel = document.getElementsByClassName( "kdfg47" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const { Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start p-4 m-0 bg-white a4rjup" },
            //Element
            React.createElement( "div", { className: "d-flex justify-content-between align-items-center cwo9uh" },
                //Element
                React.createElement( "div", { className: "float-start rounded-circle i562jl" },
                    //Element
                    React.createElement( "span", { className: "d-inline-block w-100 text-center text-white" }, "C" )
                ),
                //Element
                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 text-start nsu2sb" }, Title ),
                //Element
                React.createElement( "button", { className: "float-end p-0 m-0 bg-transparent border-0 j9h4yq", type: "button", onClick: this.Close },
                    //Element
                    React.createElement( OP7WOW, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 h-auto p-4 ps-0 pe-0 pt-2 m-0 bg-white overflow-hidden gu8r99" },
                //Element
                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 r63d15" }, "Uçağımda rötar olursa ekstra bir ödeme yapmam gerekecek mi?" ),
                //Element
                React.createElement( "p", { className: "float-start w-100" }, "Uçağınız rötar yapsa bile vermiş olduğunuz uçuş kodundan takibini sağlıyoruz. Uçağınızın rötarı sebebiyle yaşanan gecikmelerde ekstra bir ücret ödemezsiniz." )
            ),
            //Element
            React.createElement( Copyright, { ID: "0", Text: "Telif Hakkı © 2018 - 2022 Cukurovatur.com Tüm Hakları Saklıdır" } )
        )
        //Element
        return (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center position-fixed start-0 end-0 top-0 bottom-0 d-none j6oapg kdfg47">
                { Content }
            </div>
        );
    }
}
//Element
Ten.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Ten;