//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";
//Install
import { ReactComponent as HU05NC } from "../../Media/Icon/001.svg";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/008.svg";
//Install
import Copyright from "../Shortcode/Copyright";
//Install
import propTypes from "prop-types";

//Element
class Offer extends Component {
    //Element
    Close = () => {
        //Element
        let Panel = document.getElementsByClassName( "cam386" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Send = async(e) => {
        //Element
        e.preventDefault()
        //Element
        emailjs.sendForm( process.env.REACT_APP_Email_GG990T, process.env.REACT_APP_Email_QCO024, "#Web_XOT921", process.env.REACT_APP_EMail_K8NY19 )
        //Element
        .then( (result) => {
            //Element
            if( result.text === "OK" ){
                //Element
                let Panel   = document.getElementsByClassName( "cam386" )[0]
                //Element
                let Success = document.getElementsByClassName( "z2grys" )[0]
                //Element
                let Body    = document.getElementsByTagName( "body" )[0]
                //Element
                Success.classList.toggle( "d-none" )
                //Element
                Panel.classList.toggle( "d-none" )
                //Element
                Body.classList.toggle( "overflow-hidden" )
            }
        }, (error) => {
            //Element
            console.log( error.text )
        })
    }
    //Element
    render() {
        //Element
        const { Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start p-4 m-0 bg-white a4rjup" },
            //Element
            React.createElement( "div", { className: "d-flex justify-content-between align-items-center cwo9uh" },
                //Element
                React.createElement( "div", { className: "float-start rounded-circle i562jl" },
                    //Element
                    React.createElement( "span", { className: "d-inline-block w-100 text-center text-white" }, "C" )
                ),
                //Element
                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 text-start nsu2sb" }, Title ),
                //Element
                React.createElement( "button", { className: "float-end p-0 m-0 bg-transparent border-0 j9h4yq", type: "button", onClick: this.Close },
                    //Element
                    React.createElement( OP7WOW, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 h-auto p-4 ps-0 pe-0 pt-3 m-0 bg-white gu8r99" },
                //Element
                React.createElement( "form", { id: "Web_XOT921", name: "Web_XOT921", className: "float-start w-100 p-0 m-0 r0s1nk", onSubmit: this.Send },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 y7bh7e" }, "Size özel teklif hazırlayabilmek için aşağıdaki bilgilere ihtiyacımız var. Doğru teklif alabilmek için girdiğiniz bilgilerinizin tam ve doğru olduğundan emin olun." )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_XJC529", name: "Web_XJC529", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-3 border-0 ukg2q3", placeholder: "Ad", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_IK003C", name: "Web_IK003C", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-3 border-0 ukg2q3", placeholder: "Soyad", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_Y929AB", name: "Web_Y929AB", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", placeholder: "Telefon numaranız", type: "tel", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_QCO024", name: "Web_QCO024", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", placeholder: "E-mail adresiniz", type: "email", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_F354PU", name: "Web_F354PU", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", placeholder: "Yolcu sayısı", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_I53P6K", name: "Web_I53P6K", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", type: "date" , required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "button", { id: "Web_R9392N", name: "Web_R9392N", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 text-white text-center h0f232", type: "submit" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 a580qx" }, "Hemen Gönder" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end w-auto p-0 m-0 xmq5yh" },
                                        //Element
                                        React.createElement( HU05NC, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Copyright, { ID: "0", Text: "Telif Hakkı © 2018 - 2022 Cukurovatur.com Tüm Hakları Saklıdır" } )
        )
        //Element
        return (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center position-fixed start-0 end-0 top-0 bottom-0 d-none j6oapg cam386">
                { Content }
            </div>
        );
    }
}
//Element
Offer.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Offer;