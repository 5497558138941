//Install
import React from "react";

//Element
function GET(Cookie) {
    //Element
    let Symbol, Name, Decode, Colon, Colons
    //Element
    Symbol = "="
    //Element
    Name   = Cookie + Symbol
    //Element
    Decode = decodeURIComponent( document.cookie )
    //Element
    Colons  = Decode.split( ";" )
    //Element
    for( let Index = 0; Index < Colons.length; Index++ ){
        //Element
        Colon = Colons[Index]
        //Element
        while( Colon.charAt(0) == ' ' ){
            //Element
            Colon = Colon.substring(1)
        }
        //Element
        if( Colon.indexOf( Name ) == 0 ){
            //Element
            return Colon.substring( Name.length, Colon.length )
        }
    }
    return ""
}
//Element
export default GET;