//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";
//Install
import { ReactComponent as HU05NC } from "../../Media/Icon/001.svg";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/008.svg";
//Install
import { ReactComponent as XU80MP } from "../../Media/Icon/009.svg";
//Install
import { ReactComponent as W41W8D } from "../../Media/Icon/010.svg";
//Install
import Copyright from "../Shortcode/Copyright";
//Install
import propTypes from "prop-types";

//Element
class Career extends Component {
    //Element
    Close = () => {
        //Element
        let Panel = document.getElementsByClassName( "p1zei3" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Experience = () => {
        //Element
        let Dropdown = document.getElementById( "Web_E991FP" )
        //Element
        let Down     = document.getElementById( "Web_L4AR75" )
        //Element
        let Up       = document.getElementById( "Web_G8I047" )
        //Element
        Dropdown.classList.toggle( "d-none" )
        //Element
        Down.classList.toggle( "d-none" )
        //Element
        Up.classList.toggle( "d-none" )
    }
    //Element
    Experience_Select = (e) => {
        //Element
        let Headline       = document.getElementById( "Web_Q07N0O" )
        //Element
        let Text           = e.nativeEvent.srcElement.textContent
        //Element
        Headline.innerHTML = Text
    }
    //Element
    Education = () => {
        //Element
        let Dropdown = document.getElementById( "Web_P8M32V" )
        //Element
        let Down     = document.getElementById( "Web_B56Q3W" )
        //Element
        let Up       = document.getElementById( "Web_G8I047" )
        //Element
        Dropdown.classList.toggle( "d-none" )
        //Element
        Down.classList.toggle( "d-none" )
        //Element
        Up.classList.toggle( "d-none" )
    }
    //Element
    Education_Select = (e) => {
        //Element
        let Headline       = document.getElementById( "Web_V629HA" )
        //Element
        let Text           = e.nativeEvent.srcElement.textContent
        //Element
        Headline.innerHTML = Text
    }
    //Element
    Send = async(e) => {
        //Element
        e.preventDefault()
        //Element
        emailjs.sendForm( process.env.REACT_APP_Email_GG990T, process.env.REACT_APP_Email_BJ476J, "#Web_VY06Q5", process.env.REACT_APP_EMail_K8NY19 )
        //Element
        .then( (result) => {
            //Element
            if( result.text === "OK" ){
                //Element
                let Panel   = document.getElementsByClassName( "p1zei3" )[0]
                //Element
                let Success = document.getElementsByClassName( "z2grys" )[0]
                //Element
                Panel.classList.toggle( "d-none" )
                //Element
                Success.classList.toggle( "d-none" )
            }
        }, (error) => {
            //Element
            console.log( error.text )
        })
    }
    //Element
    render() {
        //Element
        const { Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start p-4 m-0 bg-white a4rjup" },
            //Element
            React.createElement( "div", { className: "d-flex justify-content-between align-items-center cwo9uh" },
                //Element
                React.createElement( "div", { className: "float-start rounded-circle i562jl" },
                    //Element
                    React.createElement( "span", { className: "d-inline-block w-100 text-center text-white" }, "C" )
                ),
                //Element
                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 text-start nsu2sb" }, Title ),
                //Element
                React.createElement( "button", { className: "float-end p-0 m-0 bg-transparent border-0 j9h4yq", type: "button", onClick: this.Close },
                    //Element
                    React.createElement( OP7WOW, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-4 ps-0 pe-0 pe-0 pt-3 m-0 bg-white gu8r99" },
                //Element
                React.createElement( "form", { id: "Web_VY06Q5", name: "Web_VY06Q5", className: "float-start w-100 p-0 m-0 r0s1nk", onSubmit: this.Send },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 y7bh7e" }, "Biz Cukurovatur.com'da üçünü de önemsiyoruz; sizi, işimizi ve çevremizi. Çalışanlarımız için, verdiğimiz söz çok güçlü; Birlikte keşfederiz, işimizden her daim keyif alırız ve birbirimize ilham veririz. Bize katılın ve farkı yakından hissedin." )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_Z831AL", name: "Web_Z831AL", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-3 border-0 ukg2q3", placeholder: "TC Kimlik Numaranız", type: "number", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_C2AN67", name: "Web_C2AN67", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-3 border-0 ukg2q3", placeholder: "Adınız", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_ZU9FY3", name: "Web_ZU9FY3", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", placeholder: "Soyadınız", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_KM88A6", name: "Web_KM88A6", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", placeholder: "Telefon numaranız", type: "tel", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_XGXZ40", name: "Web_XGXZ40", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", placeholder: "E-mail adresiniz", type: "email", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_G3Z3YP", name: "Web_G3Z3YP", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 ukg2q3", placeholder: "Doğum Tarihi", type: "date", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative p-0 m-0 mt-4", onClick: this.Experience },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 pe-4 ps-4 m-0 k7ko21" },
                                    //Element
                                    React.createElement( "span", { id: "Web_Q07N0O", className: "float-start p-0 m-0 tlux1x" }, "Tecrübe" ),
                                    //Element
                                    React.createElement( "span", { id: "Web_L4AR75", className: "d-flex justify-content-end align-items-center p-0 m-0 mqy34l" },
                                        //Element
                                        React.createElement( XU80MP, {} )
                                    ),
                                    //Element
                                    React.createElement( "span", { id: "Web_P07LC7", className: "d-flex justify-content-end align-items-center p-0 m-0 d-none mqy34l" },
                                        //Element
                                        React.createElement( W41W8D, {} )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Web_E991FP", className: "float-start w-100 p-0 ps-4 pe-4 m-0 position-absolute bg-white d-none i3qpcs" },
                                    //Element
                                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0", onClick: this.Experience_Select }, "1-3 Yıl" )
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0", onClick: this.Experience_Select }, "4-5 Yıl" )
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0", onClick: this.Experience_Select }, "6-10 Yıl" )
                                        )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative p-0 m-0 mt-4", onClick: this.Education },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 pe-4 ps-4 m-0 k7ko21" },
                                    //Element
                                    React.createElement( "span", { id: "Web_V629HA", className: "float-start p-0 m-0 tlux1x" }, "Eğitim Durumu" ),
                                    //Element
                                    React.createElement( "span", { id: "Web_B56Q3W", className: "d-flex justify-content-end align-items-center p-0 m-0 mqy34l dmcq63" },
                                        //Element
                                        React.createElement( XU80MP, {} )
                                    ),
                                    //Element
                                    React.createElement( "span", { id: "Web_G8I047", className: "d-flex justify-content-end align-items-center p-0 m-0 d-none mqy34l j0d4qh" },
                                        //Element
                                        React.createElement( W41W8D, {} )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Web_P8M32V", className: "float-start w-100 p-0 ps-4 pe-4 m-0 position-absolute bg-white d-none i3qpcs" },
                                    //Element
                                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0", onClick: this.Education_Select }, "İlkokul" )
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0", onClick: this.Education_Select }, "Ortaokul" )
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0", onClick: this.Education_Select }, "Lise" )
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0", onClick: this.Education_Select }, "Üniversite" )
                                        )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "textarea", { id: "Web_P46133", name: "Web_P46133", className: "float-start w-100 p-3 ps-4 pe-4 m-0 mt-4 r7elfb", row: "4", cols: "50", placeholder: "Özgeçmiş", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "button", { id: "Web_CU8TWS", name: "Web_CU8TWS", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 text-white text-center h0f232", type: "submit" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 a580qx" }, "Hemen Gönder" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end w-auto p-0 m-0 xmq5yh" },
                                        //Element
                                        React.createElement( HU05NC, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Copyright, { ID: "0", Text: "Telif Hakkı © 2018 - 2022 Cukurovatur.com Tüm Hakları Saklıdır" } )
        )
        //Element
        return (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center position-fixed start-0 end-0 top-0 bottom-0 d-none j6oapg p1zei3">
                { Content }
            </div>
        );
    }
}
//Element
Career.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Career;