//Install
import React, { Component } from "react";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/008.svg";
//Install
import Copyright from "../Shortcode/Copyright";
//Install
import propTypes from "prop-types";

//Element
class Price extends Component {
    //Element
    Close = () => {
        //Element
        let Panel = document.getElementsByClassName( "y8rogc" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const { Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start p-4 m-0 bg-white a4rjup" },
            //Element
            React.createElement( "div", { className: "d-flex justify-content-between align-items-center cwo9uh" },
                //Element
                React.createElement( "div", { className: "float-start rounded-circle i562jl" },
                    //Element
                    React.createElement( "span", { className: "d-inline-block w-100 text-center text-white" }, "C" )
                ),
                //Element
                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 text-start nsu2sb" }, Title ),
                //Element
                React.createElement( "button", { className: "float-end p-0 m-0 bg-transparent border-0 j9h4yq", type: "button", onClick: this.Close },
                    //Element
                    React.createElement( OP7WOW, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 h-auto p-4 ps-0 pe-0 pt-2 pb-2 m-0 bg-white overflow-hidden gu8r99" },
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 m-0 kmm903" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0" }, "Citroen Elysee 1.6 BlueHDI" ),
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0 text-end" }, "399.99 TL" )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0" }, "Fiat Egea 1.6 Multijet" ),
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0 text-end" }, "399.99 TL" )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0" }, "Mercedes-Benz C 200 d BlueTEC" ),
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0 text-end" }, "599.99 TL" )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0" }, "Mercedes-Benz Vito 116 CDI" ),
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0 text-end" }, "799.99 TL" )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0" }, "Mercedes-Benz Sprinter 516 CDI" ),
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0 text-end" }, "1.499.99 TL" )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0" }, "Mercedes - Benz Travego 17 SHD" ),
                        //Element
                        React.createElement( "span", { className: "float-start w-50 p-0 m-0 text-end" }, "2.099.99 TL" )
                    )
                )
            ),
            //Element
            React.createElement( Copyright, { ID: "0", Text: "Telif Hakkı © 2018 - 2022 Cukurovatur.com Tüm Hakları Saklıdır" } )
        )
        //Element
        return (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center position-fixed start-0 end-0 top-0 bottom-0 d-none j6oapg y8rogc">
                { Content }
            </div>
        );
    }
}
//Element
Price.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Price;