//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as YZV733 } from "../../Media/Icon/012.svg";
//Install
import { ReactComponent as C046VQ } from "../../Media/Icon/013.svg";

//Element
class Navigation extends Component {
    //Element
    Home = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "/"
    }
    //Element
    About = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "h418q5" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.add( "overflow-hidden" )
    }
    //Element
    Car = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "vqj03t" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Career = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "p1zei3" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Contact = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "eo3q6d" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Reference = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "o52cca" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Campaign = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "p93hn3" )[0]
        //Element
        let Nav   = document.getElementsByClassName( "dxi945" )[0]
        //Element
        Nav.classList.toggle( "d-none" )
        //Element
        Panel.scrollIntoView()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-fixed d-none dxi945" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 n4726c" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Transfer", href: "/", onClick: this.Home },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 mo96t4" }, "Transfer" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 t916er" },
                                        //Element
                                        React.createElement( C046VQ, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kurumsal", href: "/", onClick: this.About },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 mo96t4" }, "Kurumsal" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 t916er" },
                                        //Element
                                        React.createElement( C046VQ, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Modeller", href: "/", onClick: this.Car },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 mo96t4" }, "Modeller" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 t916er" },
                                        //Element
                                        React.createElement( C046VQ, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kampanyalar", href: "/", onClick: this.Campaign },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 mo96t4" }, "Kampanyalar" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 t916er" },
                                        //Element
                                        React.createElement( C046VQ, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Referanslar", href: "/", onClick: this.Reference },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 mo96t4" }, "Referanslar" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 t916er" },
                                        //Element
                                        React.createElement( C046VQ, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kariyer", href: "/", onClick: this.Career },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 mo96t4" }, "Kariyer" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 t916er" },
                                        //Element
                                        React.createElement( C046VQ, {} )
                                    )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Bize Ulaşın", href: "/", onClick: this.Contact },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 mo96t4" }, "Bize Ulaşın" ),
                                    //Element
                                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 t916er" },
                                        //Element
                                        React.createElement( C046VQ, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Navigation;