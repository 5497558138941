//Install
import React, { Component } from "react";

//Element
class Menu extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    About = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "h418q5" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Car = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "vqj03t" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Career = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "p1zei3" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Contact = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "eo3q6d" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Reference = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "o52cca" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Campaign = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "p93hn3" )[0]
        //Element
        Panel.scrollIntoView()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "ul", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 t58kid" },
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "button", { className: "float-start p-0 m-0 bg-transparent border-0 owz85h", type: "button", onClick: this.Home },
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 position-relative" }, "Transfer" )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "button", { className: "float-start p-0 bg-transparent border-0 owz85h", type: "button", onClick: this.About },
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 position-relative" }, "Kurumsal" )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "button", { className: "float-start p-0 bg-transparent border-0 owz85h", type: "button", onClick: this.Car },
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 position-relative" }, "Modeller" )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "button", { className: "float-start p-0 bg-transparent border-0 owz85h", type: "button", onClick: this.Campaign },
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 position-relative" }, "Kampanyalar" )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "button", { className: "float-start p-0 bg-transparent border-0 owz85h", type: "button", onClick: this.Reference },
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 position-relative" }, "Referanslar" )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "button", { className: "float-start p-0 bg-transparent border-0 owz85h", type: "button", onClick: this.Career },
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 position-relative" }, "Kariyer" )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start" },
                    //Element
                    React.createElement( "button", { className: "float-start p-0 bg-transparent border-0 owz85h", type: "button", onClick: this.Contact },
                        //Element
                        React.createElement( "span", { className: "float-start p-0 m-0 position-relative" }, "Bize Ulasın" )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Menu;