//Install
import React, { Component } from "react";
//Install
import About from "../Modal/About";
//Install
import Car from "../Modal/Car";
//Install
import Career from "../Modal/Career";
//Install
import Contact from "../Modal/Contact";
//Install
import Offer from "../Modal/Offer";
//Install
import Price from "../Modal/Price";
//Install
import Reference from "../Modal/Reference";
//Install
import Complete from "../Modal/Complete";
//Install
import Campaign from "../Modal/Campaign";

//Element
class Assets extends Component {
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( About, { ID: "0", Title: "Kurumsal" } ),
            //Element
            React.createElement( Car, { ID: "0", Title: "Araçlar" } ),
            //Element
            React.createElement( Career, { ID: "0", Title: "Kariyer" } ),
            //Element
            React.createElement( Contact, { ID: "0", Title: "Bize Ulaşın" } ),
            //Element
            React.createElement( Offer, { ID: "0", Title: "Online Teklif Alın" } ),
            //Element
            React.createElement( Price, { ID: "0", Title: "Transfer Ücretleri" } ),
            //Element
            React.createElement( Reference, { ID: "0", Title: "Kimlere Hizmet Sunuldu?" } ),
            //Element
            React.createElement( Complete, { ID: "0", Title: "Bildirim Gönderildi" } ),
            //Element
            React.createElement( Campaign, { ID: "0", Title: "Kampanyalar" } )
        )
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Assets;