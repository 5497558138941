//Install
import React, { Component } from "react";
//Install
import { ReactComponent as MDF96L } from "../../Media/Social/Facebook.svg";
//Install
import { ReactComponent as D701ZO } from "../../Media/Social/Instagram.svg";
//Install
import { ReactComponent as ZUCH4G } from "../../Media/Social/Youtube.svg";
//Install
import { ReactComponent as P709P9 } from "../../Media/Social/Linkedin.svg";
//Install
import { ReactComponent as VK3SJQ } from "../../Media/Social/WhatsApp.svg";

//Element
class Social extends Component {
    //Element
    render() {
        //Element
        const { Facebook, WhatsApp, Instagram, Youtube, Linkedin } = this.props
        //Element
        const Content = React.createElement( "ul", { className: "float-end p-0 m-0 mt-2 z3p36l" },
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "d-flex justify-content-center align-items-center", title: "Facebook", href: Facebook },
                    //Element
                    React.createElement( MDF96L, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "d-flex justify-content-center align-items-center", title: "WhatsApp", href: WhatsApp },
                    //Element
                    React.createElement( VK3SJQ, { className: "fwtz71" } )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "d-flex justify-content-center align-items-center", title: "Instagram", href: Instagram },
                    //Element
                    React.createElement( D701ZO, { className: "xbfy25" } )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "d-flex justify-content-center align-items-center", title: "Youtube", href: Youtube },
                    //Element
                    React.createElement( ZUCH4G, { className: "k9x11q" } )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "d-flex justify-content-center align-items-center", title: "Linkedin", href: Linkedin },
                    //Element
                    React.createElement( P709P9, {} )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-auto">
                { Content }
            </div>
        );
    }
}
//Element
export default Social;