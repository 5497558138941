//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/008.svg";

//Element
class Top extends Component {
    //Element
    Create = () => {
        //Element
        document.cookie = "Cookie=OK"
        //Element
        let Panel       = document.getElementsByClassName( "d4uzu4" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:11 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 position-relative" },
                        //Element
                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 wu5vo5" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:1 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center ognt04" },
                        //Element
                        React.createElement( "button", { id: "Web_U1GJ8L", name: "Web_U1GJ8L", className: "d-flex justify-content-center align-items-center p-0 m-0 text-white text-center r1n3af", type: "button", onClick: this.Create },
                            //Element
                            React.createElement( OP7WOW, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Top;