//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";
//Install
import { ReactComponent as OP7WOW } from "../../Media/Icon/001.svg";
//Install
import { ReactComponent as Q4TDB9 } from "../../Media/Icon/002.svg";
//Install
import propTypes from "prop-types";

//Element
class Reservation extends Component {
    //Element
    Send = async(e) => {
        //Element
        e.preventDefault()
        //Element
        emailjs.sendForm( process.env.REACT_APP_Email_GG990T, process.env.REACT_APP_Email_QCO024, "#Web_K4614J", process.env.REACT_APP_EMail_K8NY19 )
        //Element
        .then( (result) => {
            //Element
            if( result.text === "OK" ){
                //Element
                let Success = document.getElementsByClassName( "z2grys" )[0]
                //Element
                let Body    = document.getElementsByTagName( "body" )[0]
                //Element
                Success.classList.toggle( "d-none" )
                //Element
                Body.classList.toggle( "overflow-hidden" )
            }
        }, (error) => {
            //Element
            console.log( error.text )
        })
    }
    //Element
    Call = () => {
        //Element
        window.location.href = "tel:+905316690519"
    }
    render() {
        //Element
        const { Title, Description, Telephone } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "div", { className: "float-start w-100 n42b1c" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center p-0 ps-5 m-0 t3se3q" },
                    //Element
                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 lbca2y" }, Title ),
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 geyp7j" }, Description )
                ),
                //Element
                React.createElement( "form", { id: "Web_K4614J", name: "Web_K4614J", className: "float-start w-100 p-0 ps-5 m-0 sn6w7z", onSubmit: this.Send },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_XJC529", name: "Web_XJC529", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-3 bg-white vn1mhu", placeholder: "Adınız", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_IK003C", name: "Web_IK003C", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-3 bg-white vn1mhu", placeholder: "Soyadınız", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_Y929AB", name: "Web_Y929AB", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 bg-white vn1mhu", placeholder: "Telefon numaranız", type: "tel", minLength: "11", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "input", { id: "Web_QCO024", name: "Web_QCO024", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 bg-white vn1mhu", placeholder: "E-posta adresiniz", type: "email" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "button", { id: "Web_CU8TWS", name: "Web_CU8TWS", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-4 border-0 text-white text-center h0f232", type: "submit" },
                                    //Element
                                    React.createElement( "span", { className: "float-start p-0 m-0" }, "Hemen Gönder" ),
                                    //Element
                                    React.createElement( "span", { className: "float-end p-0 m-0 xmq5yh" },
                                        //Element
                                        React.createElement( OP7WOW, {} )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-between position-relative" },
                                //Element
                                React.createElement( "button", { id: "Web_N10LNP", name: "Web_N10LNP", className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 position-relative d337li", type: "button", onClick: this.Call },
                                    //Element
                                    React.createElement( "span", { className: "d-flex justify-content-center align-items-center p-0 rounded-circle mcks4k" },
                                        //Element
                                        React.createElement( Q4TDB9, {} )
                                    ),
                                    //Element
                                    React.createElement( "span", { className: "float-end position-absolute end-0 top-0 p-0 m-0 b644mm" }, Telephone ),
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Reservation.propTypes = {
    ID          : propTypes.string.isRequired,
    Title       : propTypes.string.isRequired,
    Description : propTypes.string.isRequired,
    Telephone   : propTypes.string.isRequired
}
//Element
export default Reservation;