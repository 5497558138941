//Install
import React, { Component } from "react";
//Install
import One from "../Question/One";
//Install
import Two from "../Question/Two";
//Install
import Three from "../Question/Three";
//Install
import Four from "../Question/Four";
//Install
import Five from "../Question/Five";
//Install
import Six from "../Question/Six";
//Install
import Seven from "../Question/Seven";
//Install
import Eight from "../Question/Eight";
//Install
import Nine from "../Question/Nine";
//Install
import Ten from "../Question/Ten";

//Element
class Questions extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( One, { ID: "0", Title: "Sıkça Sorulan Sorular" } ),
            //Element
            React.createElement( Two, { ID: "0", Title: "Sıkça Sorulan Sorular" } ),
            //Element
            React.createElement( Three, { ID: "0", Title: "Sıkça Sorulan Sorular" } ),
            //Element
            React.createElement( Four, { ID: "0", Title: "Sıkça Sorulan Sorular" } ),
            //Element
            React.createElement( Five, { ID: "0", Title: "Sıkça Sorulan Sorular" } ),
            //Element
            React.createElement( Six, { ID: "0", Title: "Sıkça Sorulan Sorular" } ),
            //Element
            React.createElement( Seven, { ID: "0", Title: "Sıkça Sorulan Sorular" } ),
            //Element
            React.createElement( Eight, { ID: "0", Title: "Sıkça Sorulan Sorular" } ),
            //Element
            React.createElement( Nine, { ID: "0", Title: "Sıkça Sorulan Sorular" } ),
            //Element
            React.createElement( Ten, { ID: "0", Title: "Sıkça Sorulan Sorular" } )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Questions;