//Install
import React, { Component } from "react";

//Element
class Copyright extends Component {
    //Element
    Contact = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "eo3q6d" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Offer = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "cam386" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start" },
            //Element
            React.createElement( "p", { className: "float-start p-0 m-0 idhp1k" }, "Telif Hakkı © 2022 Cukurovatur.com Tüm Hakları Saklıdır" ),
            //Element
            React.createElement( "a", { className: "float-start p-0 m-0 c7gxvi", title: "Müşteri İletişim Formu", href: "/", onClick: this.Contact }, "Müşteri İletişim Formu" ),
            //Element
            React.createElement( "a", { className: "float-start p-0 m-0 gygc7b", title: "Online Talep Formu", href: "/", onClick: this.Offer }, "Online Talep Formu" )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Copyright;