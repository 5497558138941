//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Reference extends Component {
    //Element
    Modal = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "o52cca" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const { Title, Photo } = this.props
        //Element
        let Source
        //Element
        Source = "https://www.cukurovatur.com/upload/reference/"
        //Element
        let Image
        //Element
        Image  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100" },
            //Element
            React.createElement( "button", { className: "float-start w-100 p-0 m-0 mt-4 bg-transparent border-0 q9uyff", type: "button", onClick: this.Modal },
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center" },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 r1a75d" },
                        //Element
                        React.createElement( "div", { className: "d-inline-block p-0 m-0 w6omp5" },
                            //Element
                            React.createElement( "img", { alt: Title, title: Title, src: Image, width: "110", height: "110" } )
                        )
                    ),
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center aa93ni" }, Title )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
Reference.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired,
    Photo : propTypes.string.isRequired
}
//Element
export default Reference;