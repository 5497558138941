//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class SET extends Component {
    //Element
    Offer = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "cam386" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    Price = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel = document.getElementsByClassName( "y8rogc" )[0]
        //Element
        let Body  = document.getElementsByTagName( "body" )[0]
        //Element
        Panel.classList.toggle( "d-none" )
        //Element
        Body.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:6 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center yc5388" },
                    //Element
                    React.createElement( "button", { id: "Web_H36LUK", name: "Web_H36LUK", className: "float-end w-100 p-0 m-0 border-0 text-center kz153u", type: "button", onClick: this.Price }, "Tüm Ücretler" ),
                )
            ),
            //Element
            React.createElement( Col, { md:6 },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center yc5388" },
                    //Element
                    React.createElement( "button", { id: "Web_W5NQ4A", name: "Web_W5NQ4A", className: "float-end w-100 p-0 m-0 border-0 text-center y31ju2", type: "button", onClick: this.Offer }, "Online Teklif" ),
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default SET;